import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/browser";

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';

import App from './App';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'hu',
  resources: {},
});

if (process.env.NODE_ENV === "production") {

  Sentry.init({
    dsn: "https://c9f7d302bfc900b08abd6889c610c33e@o4506129787322368.ingest.sentry.io/4506180854022144",

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: "trackie-webapp@2.0",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App/>
      </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
