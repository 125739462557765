import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, Form} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {AutoComplete} from "primereact/autocomplete";
import {addLocale} from "primereact/api";
import {InputMask} from "primereact/inputmask";
import PartnerListService from "../../services/partner/PartnerListService";
import axios from "axios";
import {Dropdown} from "primereact/dropdown";

const ProductionitemSubcontractorFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if ((window.App.state.productionitemSubcontractorFormMethod && window.App.state.productionitemSubcontractorFormMethod === 'update')) {
      axios({
        method: 'patch',
        url: process.env.REACT_APP_API_HOST + "/api/v2/production-item/subcontractor-list/" + window.App.state.productionitemSubcontractorRowData.id,
        data: data,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setFormData(response.data.data)
          window.App.setState({
            rerenderProductionitemSubcontractorList: true
          })
          setLoading(false)
          window.App.toastShow('success', 'Sikeres módosítás!');
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // partner autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartner = (event) => {
    setSelectedPartner(event.query.replace('Nincs találat', '').replace(': ', ''))
    PartnerListService(JSON.stringify({
      filters:
        {
          name: {value: event.query.replace('Nincs találat', '').replace(': ', '')},
          vendor: {value: true}
        }
    }))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setPartners(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedPartner(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/production_status",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setStatuses(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (window.App.state && window.App.state.productionitemSubcontractorRowData && window.App.state.productionitemSubcontractorRowData.id) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/api/v2/production-item/subcontractor-list/" + window.App.state.productionitemSubcontractorRowData.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setSelectedPartner(response.data.data.subcontractor_name)
          setFormData(response.data.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel>
          <div className={"p-grid"}>

            <div className={"p-col-12 p-lg-6"}>
              <Field name="subcontractor" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="subcontractor"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Alvállalkozó</label>
                  <span className="p-d-block">
                    <AutoComplete value={selectedPartner}
                                  id={"subcontractor"}
                                  suggestions={partners}
                                  forceSelection
                                  completeMethod={searchPartner}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      subcontractor: e.value.id,
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      subcontractor: null,
                                    })
                                    setSelectedPartner(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedPartner('')
                                  }}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="deadline" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="deadline"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Határidő</label>
                  <span className={"p-d-block"}>
                        <InputMask id={"deadline"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.deadline}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         deadline: e.value + " 00:00:00"
                                       })
                                     }
                                   }}
                        />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="status" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="identityDocumentType"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Státusz</label>
                  <span className="p-d-block">
                    <Dropdown options={statuses}
                              optionLabel="name"
                              optionValue="id"
                              id={"status"}
                              emptyMessage={"Még nincs státusz..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  status: e.target.value
                                })
                              }}
                              value={formData.status}
                              showClear
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProductionitemSubcontractorFormComponent);
