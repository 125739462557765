import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios";
import moment from "moment/moment";
import {Button} from "primereact/button";

const ProductionitemSubcontratorShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const status = (stat) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === stat)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/production_status",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setStatuses(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (window.App.state.propsProductionitemSubcontrator && window.App.state.propsProductionitemSubcontrator.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/api/v2/production-item/subcontractor-list/" + window.App.state.propsProductionitemSubcontrator.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">Név</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="project_number">{t('projectNumber')}</label>
              <span className="p-d-block p-text-bold">
                {formData.project_number}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="project_name">{t('project')}</label>
              <span className="p-d-block p-text-bold">
                {formData.project_name} <i className={"pi pi-window-maximize"}
                                           onClick={() => {
                                             window.App.setState({
                                               sidebarProjectShow: true,
                                               props: {id: formData.project_item.project.id},
                                               propsProject: {id: formData.project_item.project.id},
                                             })
                                           }}
                                        ></i>
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="project_item_name">{t('project') + " tétel"}</label>
              <span className="p-d-block p-text-bold">
                {formData.project_item_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="external_reference">{t('externalReference')}</label>
              <span className="p-d-block p-text-bold">
                {formData.external_reference}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="serial">Sorozatszám</label>
              <span className="p-d-block p-text-bold">
                {formData.serial}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="technology_name">Technológia</label>
              <span className="p-d-block p-text-bold">
                {formData.technology_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="machine_name">Gép</label>
              <span className="p-d-block p-text-bold">
                {formData.machine_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="partner_name">{t('partnerName')}</label>
              <span className="p-d-block p-text-bold">
                {formData.partner_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="subcontractor_name">Alvállalkozó</label>
              <span className="p-d-block p-text-bold">
                {formData.subcontractor_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="status">Státusz</label>
              <span className="p-d-block p-text-bold">
                {status(formData.status)}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="status">Mennyiség</label>
              <span className="p-d-block p-text-bold">
                {formData.quantity} {formData.unit_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="deadline">{t('deadline')}</label>
              <span className="p-d-block p-text-bold">
                {formData.deadline !== null &&
                <>
                  {moment.utc(formData.deadline).local().format('YYYY-MM-DD')}
                </>
                }
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="estimated_time">Becsült idő</label>
              <span className="p-d-block p-text-bold">
                {formData.estimated_time}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="production_time">Munkaidő</label>
              <span className="p-d-block p-text-bold">
                {formData.production_time}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="created_at_snapshot">Létrehozva</label>
              <span className="p-d-block p-text-bold">
                {formData.created_at_snapshot !== null &&
                <>
                  {moment.utc(formData.created_at_snapshot).local().format('YYYY-MM-DD HH:mm:ss')}
                </>
                }
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="updated_at_snapshot">Módosítva</label>
              <span className="p-d-block p-text-bold">
                {formData.updated_at_snapshot !== null &&
                <>
                  {moment.utc(formData.updated_at_snapshot).local().format('YYYY-MM-DD HH:mm:ss')}
                </>
                }
              </span>
            </div>
          </div>

        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(ProductionitemSubcontratorShowComponent);
