import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Panel} from "primereact/panel";
import moment from "moment";
import currencyFormatter from "currency-formatter";
import {InputNumber} from "primereact/inputnumber";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {InputMask} from "primereact/inputmask";
import {SplitButton} from "primereact/splitbutton";

import ProjectListService from "../../services/project/ProjectListService";
import ProjecttypeListService from "../../services/projecttype/ProjecttypeListService";
import ProjectCopyService from "../../services/project/ProjectCopyService";

const ProjectListComponent = (props) => {

  const {t} = props;
  const [project, setProject] = useState([]);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [view, setView] = useState('simple');
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProjectList: false})
      ProjectListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProject(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const dt = useRef(null);

  const deleteProject = (rowData) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_HOST + "/api/v2/project/" + rowData.id,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_project_write') &&
          <>
            <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                    tooltip="Módosítás"
                    onClick={() => {
                      window.App.setState({
                        sidebarProjectUpdate: true,
                        props: rowData
                      })
                    }}/>
            <Button icon="pi pi-clone" className=" p-button-warning p-mr-2"
                    tooltip="Másolás"
                    onClick={() => {
                      if (window.confirm('A másolás megerősítésére van szükség!')) {
                        ProjectCopyService(rowData.id)
                          .then(response => {
                            window.App.setState({
                              props: response.data,
                              rerenderProjectList: true,
                              sidebarProjectUpdate: true,
                            })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }
                    }}/>
          </>
        }
        <Button icon="pi pi-eye" className=" p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarProjectShow: true,
                    props: rowData,
                    propsProject: rowData,
                  })
                }}/>
        {localStorage.getItem('roles').toString().includes('role_project_write') &&
          <>
            {rowData && rowData.deleted_at_snapshot ? (
              <Button icon="pi pi-undo" className="p-button-sm p-button-success"
                      tooltip="Visszaállítás"
                      tooltipOptions={{position: 'left'}}
                      onClick={() => {
                        axios({
                          method: 'get',
                          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "/api/v2/project/" + rowData.id + "/restore",
                          params: {},
                          headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                            'App-Network': localStorage.getItem("networkId")
                          }
                        })
                          .then(response => {
                            window.App.toastShow('success', t('success_restore'),);
                            loadLazyData();
                          })
                          .catch(error => {
                            window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                          })
                      }}
              />
            ) : (
              <Button icon="pi pi-trash" className=" p-button-danger"
                      tooltip="Törlés"
                      onClick={() => {
                        if (window.confirm('A törlés megerősítésére van szükség!')) {
                          deleteProject(rowData)
                        }
                      }}
              />
            )}
          </>
        }
      </React.Fragment>
    );
  }


  const [filterProjectnumber, setFilterProjectnumber] = useState();
  const [filterName, setFilterName] = useState();
  const [filterProjectstatusname, setFilterProjectstatusname] = useState();
  const [filterProjecttype, setFilterProjecttype] = useState();
  const [filterPartnername, setFilterPartnername] = useState();
  const [filterContractnumber, setFilterContractnumber] = useState();
  const [filterDeadlinefrom, setFilterDeadlinefrom] = useState();
  const [filterDeadlineuntil, setFilterDeadlineuntil] = useState();
  const [filterNetAmountMax, setFilterNetAmountMax] = useState();
  const [filterNetAmountMin, setFilterNetAmountMin] = useState();
  const [filterCreatedatfrom, setFilterCreatedatfrom] = useState();
  const [filterCreatedatuntil, setFilterCreatedatuntil] = useState();
  const [filterCreatedbyname, setFilterCreatedbyname] = useState();
  const [filterHasSubcontractor, setFilterHasSubcontractor] = useState();
  const [searchCollapsed, setSearchCollapsed] = useState();

  const searchHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    return (
      <>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center"}>
            <h3 className={"p-p-0 p-m-0"}>
              <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
              </button>
              &nbsp; Szűrés
            </h3>
          </div>
          <div className={"p-col-12 p-lg-6 p-text-right p-col-align-center"}>
            <Button className={"p-button-danger p-mr-2 p-text-light"}
                    label="Szűrés törlése" icon="pi pi-times"
                    onClick={() => {
                      //setSelectedReceiptType('')
                      setLazyParams({
                        first: 0,
                        rows: rows,
                        page: 0,
                        sortField: 'id',
                        sortOrder: 1,
                      })
                      window.App.setState({
                        lazyparamsReceiptList: null
                      })
                    }}
            />

            {lazyParams && lazyParams.deleted === 1 ? (
              <Button type="button" icon={"pi pi-eye-slash"}
                      onClick={() => {
                        let _lazyParams = lazyParams
                        delete lazyParams.deleted
                        setLazyParams(_lazyParams)
                        loadLazyData()
                      }}
                      className="p-button-danger"/>
            ) : (
              <Button type="button" icon={"pi pi-eye"}
                      onClick={() => {
                        setLazyParams({
                          ...lazyParams,
                          deleted: 1
                        })
                      }}
                      className="p-button-success"/>
            )}
            {localStorage.getItem('roles').toString().includes('role_project_write') &&
              <>
                <SplitButton label="Új"
                             id="buttonAdd"
                             onClick={() => {
                               document.getElementById('buttonAdd').getElementsByClassName('p-splitbutton-menubutton')[0].click()
                             }}
                             icon="pi pi-plus"
                             className={"p-button-success p-ml-md-2"}
                             model={projectTypes}/>
              </>
            }
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    loadLazyData();
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/api/v2/project-status?" + JSON.stringify({
        first: 0,
        rows: 9999,
        page: 0,
      }),
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setProjectStatuses(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ProjecttypeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        let _types = []
        {response.data.items.map((type, index) => {
          if (type.active === true) {
            _types.push(
              {
                label: type.name + " " + t('project').toLowerCase(),
                command: () => {
                  window.App.setState({
                    sidebarProjectCreate: true,
                    props: {
                      projectType: {
                        id: type.id
                      }
                    }
                  })
                }
              },
            )
          }
        })}
        setProjectTypes(_types);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    // const textFilters = document.querySelectorAll('.p-inputtext.p-column-filter');
    // for (const filter of textFilters) {
    //   filter.outerHTML += '<i class="pi pi-times p-ml-1" onClick="javascript:this.parentElement.children[0].value = \'\'"></i>';
    // }
  }, [lazyParams, window.App.state.rerenderProjectList === true])

  return (
    <>
      {props && view === 'advanced' &&
        <>
          <Panel headerTemplate={searchHeaderTemplate} toggleable collapsed={searchCollapsed}
                 onToggle={(e) => {
                   //setSearchCollapsed(e.value)
                   if(e.value === true){
                     setView('simple')
                   } else {
                     setView('advanced')
                   }
                 }}
                 className={"p-pb-2"}
                 id={"filters"}
          >
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>{t('projectNumber')}</label>
                  <span className={"p-d-block"}>
                  <InputText id="name"
                             onChange={(e) => {
                               setFilterProjectnumber(e.target.value)
                               let value = e.target.value
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(value, 'projectNumber', '');
                               }, 500)
                             }}
                             value={filterProjectnumber}
                  />
                </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>Státusz</label>
                  <span className={"p-d-block"}>
                  <Dropdown options={projectStatuses}
                            optionLabel="name"
                            optionValue="id"
                            id={"projectStatus"}
                            emptyMessage={"Még nincs típus..."}
                            onChange={(e) => {
                              setFilterProjectstatusname(e.value)
                              dt.current.filter(e.value, 'projectStatus', '');
                            }}
                            showClear
                            value={filterProjectstatusname}
                  />
              </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>Típus</label>
                  <span className={"p-d-block"}>
                  <Dropdown options={projectTypes}
                            optionLabel="name"
                            optionValue="id"
                            id={"projectType"}
                            emptyMessage={"Még nincs típus..."}
                            onChange={(e) => {
                              setFilterProjecttype(e.value)
                              dt.current.filter(e.value, 'projectType', '');
                            }}
                            showClear
                            value={filterProjecttype}
                  />
              </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>Megnevezés</label>
                  <span className={"p-d-block"}>
                  <InputText id="name"
                             onChange={(e) => {
                               setFilterName(e.target.value)
                               let value = e.target.value
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(value, 'name', '');
                               }, 500)
                             }}
                             value={filterName}
                  />
                </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>{t('partnerName')}</label>
                  <span className={"p-d-block"}>
                  <InputText id="partnerName"
                             onChange={(e) => {
                               setFilterPartnername(e.target.value)
                               let value = e.target.value
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(value, 'partnerName', '');
                               }, 500)
                             }}
                             value={filterPartnername}
                  />
                </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>
                    {t('deadline')}
                  </label>
                  <div className={"p-grid"}>
                    <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"paidDateFrom"}
                               value={filterDeadlinefrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterDeadlinefrom(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'deadlineFrom', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                    </div>
                    <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                      <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                    </div>
                    <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"paidDateTo"}
                               value={filterDeadlineuntil}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterDeadlineuntil(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'deadlineUntil', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-1"}>
                <div className="p-field p-fluid">
                  <label>Min. nettó</label>
                  <span className={"p-d-block"}>
                <InputNumber id="netAmountMin"
                             locale="hu-HU"
                             minFractionDigits={0}
                             maxFractionDigits={9}
                             onChange={(e) => {
                               setFilterNetAmountMin(e.value)
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(e.value, 'netAmountMin', '');
                               }, 500)
                             }}
                             value={filterNetAmountMin}
                />
              </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-1"}>
                <div className="p-field p-fluid">
                  <label>Max. nettó</label>
                  <span className={"p-d-block"}>
                <InputNumber id="netAmountMax"
                             locale="hu-HU"
                             minFractionDigits={0}
                             maxFractionDigits={9}
                             onChange={(e) => {
                               setFilterNetAmountMax(e.value)
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(e.value, 'netAmountMax', '');
                               }, 500)
                             }}
                             value={filterNetAmountMax}
                />
              </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>
                    Létrehozva
                  </label>
                  <div className={"p-grid"}>
                    <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"createdAtFrom"}
                               value={filterCreatedatfrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterCreatedatfrom(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'createdAtFrom', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                    </div>
                    <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                      <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                    </div>
                    <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"createdAtUntil"}
                               value={filterCreatedatuntil}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterCreatedatuntil(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'createdAtUntil', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>Létrehozta</label>
                  <span className={"p-d-block"}>
                  <InputText id="createdByName"
                             onChange={(e) => {
                               setFilterCreatedbyname(e.target.value)
                               let value = e.target.value
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(value, 'createdByName', '');
                               }, 500)
                             }}
                             value={filterCreatedbyname}
                  />
                </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <div className="p-field p-fluid">
                  <label>Alvállalkozói közreműködés</label>
                  <span className={"p-d-block"}>
                  <Dropdown options={[
                    {
                      label: "igen",
                      value: true
                    },
                    {
                      label: "nem",
                      value: false
                    },
                  ]}
                            optionLabel="label"
                            optionValue="value"
                            id={"hasSubcontractor"}
                            emptyMessage={"Még nincs opció..."}
                            onChange={(e) => {
                              setFilterHasSubcontractor(e.value)
                              dt.current.filter(e.value, 'hasSubcontractor', '');
                            }}
                            showClear
                            value={filterHasSubcontractor}
                  />
              </span>
                </div>
              </div>
            </div>
          </Panel>
        </>
      }
      {view === 'simple' &&
        <>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>
              <Button className={"p-button-outlined p-button-danger  p-mr-2"}
                      label="Szűrés törlése" icon="pi pi-times"
                      style={{border: 'none'}}
                      onClick={() => {
                        setLazyParams({
                          first: 0,
                          rows: rows,
                          page: 0,
                          sortField: 'id',
                          sortOrder: 1,
                        })
                      }}
              />
              <Button className={"p-button-outlined  "}
                      style={{border: 'none'}}
                      label="Részletes keresés" icon="pi pi-search"
                      onClick={() => {
                        setView('advanced')
                        // window.App.setState({
                        //   lazyparamsReceiptList: {
                        //     first: 0,
                        //     rows: 10,
                        //     page: 0,
                        //     sortField: 'id',
                        //     sortOrder: 1,
                        //   },
                        //   sidebarProjectList: true
                        // })
                      }}
              />
            </div>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
              {lazyParams && lazyParams.deleted === 1 ? (
                <Button type="button" icon={"pi pi-eye-slash"}
                        onClick={() => {
                          let _lazyParams = lazyParams
                          delete lazyParams.deleted
                          setLazyParams(_lazyParams)
                          loadLazyData()
                        }}
                        className="p-button-danger"/>
              ) : (
                <Button type="button" icon={"pi pi-eye"}
                        onClick={() => {
                          setLazyParams({
                            ...lazyParams,
                            deleted: 1
                          })
                        }}
                        className="p-button-success"/>
              )}
              {localStorage.getItem('roles').toString().includes('role_project_write') &&
                <>
                  <SplitButton label="Új"
                               id="buttonAdd"
                               onClick={() => {
                                 document.getElementById('buttonAdd').getElementsByClassName('p-splitbutton-menubutton')[0].click()
                               }}
                               icon="pi pi-plus"
                               className={"p-button-success p-ml-md-2"}
                               model={projectTypes}/>
                </>
              }
            </div>
          </div>
        </>
      }
      <DataTable size={localStorage.getItem('scale')}
                 emptyMessage="Nincs találat."
                 value={project} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
                 onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                 onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
                 paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
                 autoLayout={true}
                 filterDisplay="row"
                 responsiveLayout="scroll"
                 stripedRows
                 ref={dt}
      >
        <Column className={"p-d-relative"} style={{position: 'relative', width: '10px', maxWidth: '10px'}}
                body={(rowData) => {
                  if (rowData && rowData.projectStatus) {
                    let status = projectStatuses.filter(item => item.id === rowData.projectStatus.id)
                    return (
                      <>
                          <span style={{
                            position: 'absolute',
                            left: '0',
                            top: '0',
                            height: '100%',
                            borderLeft: '10px solid ' + status[0].color,
                          }}></span>
                      </>
                    )
                  }
                }}/>
        {view === 'advanced' ? (
          <Column field="projectNumber" header={t('projectNumber')} sortable></Column>
        ) : (
          <Column field="projectNumber" header={t('projectNumber')} sortable filter
                  filterPlaceholder={t('projectNumber')}></Column>
        )}
        {view === 'advanced' ? (
          <Column field="partnerName" header={t('partnerName')} sortable></Column>
        ) : (
          <Column field="partnerName" header={t('partnerName')} sortable filter
                  filterPlaceholder={t('partnerName')}></Column>
        )}
        {view === 'advanced' ? (
          <Column field="name" header="Megnevezés" sortable></Column>
        ) : (
          <Column field="name" header="Megnevezés" sortable filter filterPlaceholder="Megnevezés"></Column>
        )}
        {view === 'advanced' ? (
          <Column field="projectTypeName" header="Típus" sortable></Column>
        ) : (
          <Column field="projectTypeName" header="Típus" sortable filter
                  filterElement={() => {
                    return (
                      <>
                        {lazyParams && lazyParams.filters && lazyParams.filters.projectType ? (
                          <Dropdown options={projectTypes}
                                    id={"projectTypes"}
                                    optionValue={"id"}
                                    optionLabel={"name"}
                                    showClear
                                    emptyMessage={"Még nincs típus..."}
                                    onChange={(e) => {
                                      dt.current.filter(e.value, 'projectType', '');
                                    }}
                                    value={lazyParams.filters.projectType.value}
                          />
                        ) : (
                          <Dropdown options={projectTypes}
                                    id={"projectTypes"}
                                    optionValue={"id"}
                                    optionLabel={"name"}
                                    showClear
                                    emptyMessage={"Még nincs típus..."}
                                    onChange={(e) => {
                                      dt.current.filter(e.value, 'projectType', '');
                                    }}
                          />
                        )}
                      </>
                    )
                  }}></Column>
        )}
        {view === 'advanced' ? (
          <Column field="projectStatusName" header="Státusz" sortable></Column>
        ) : (
          <Column field="projectStatusName" header="Státusz" sortable filter
                  filterElement={() => {
                    let filterValue = null
                    if (lazyParams && lazyParams.filters && lazyParams.filters.projectStatus) {
                      filterValue = lazyParams.filters.projectStatus.value
                    }
                    return (
                      <>
                        <Dropdown options={projectStatuses}
                                  id={"projectStatuses"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs státusz..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'projectStatus', '');
                                  }}
                                  value={filterValue}
                        />
                      </>
                    )
                  }}></Column>
        )}
        {/*{view === 'advanced' ? (*/}
        {/*  <Column field="contractNumber" header="Szerződés" sortable></Column>*/}
        {/*) : (*/}
        {/*  <Column field="contractNumber" header="Szerződés" filter filterPlaceholder="Szerződés száma"*/}
        {/*          sortable></Column>*/}
        {/*)}*/}
        {view === 'advanced' &&
          <Column field="deadline" header={t('deadline')} sortable className={"p-text-nowrap"} body={(rowData) => {
            if (rowData.deadline && rowData.deadline !== null) {
              return (
                <>{moment.utc(rowData.deadline).local().format('YYYY-MM-DD')}</>
              )
            }
          }}></Column>
        }
        {view === 'advanced' &&
          <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                  alignHeader="right"
                  body={(rowData) => {
                    let currency
                    if (rowData.currency === 'HUF') {
                      currency = {
                        precision: 2,
                        symbol: '',
                        decimal: ',',
                        thousand: ' ',
                      }
                    } else {
                      currency = {
                        precision: 2,
                        symbol: '',
                        decimal: '.',
                        thousand: ' ',
                      }
                    }
                    return (
                      <>
                        {currencyFormatter.format(
                          rowData.netAmount, {
                            precision: currency.precision,
                            symbol: currency.symbol,
                            decimal: currency.decimal,
                            thousand: currency.thousand,
                          }
                        )}&nbsp;{rowData.currency}
                      </>
                    )
                  }}></Column>
        }
        <Column field="createdAt" header="Létrehozva" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
          )
        }}></Column>
        {view === 'advanced' &&
          <Column field="createdByName" header="Létrehozta" sortable></Column>
        }
        {view === 'advanced' &&
          <Column field="hasSubcontractor" header="Alvállalkozó" sortable body={(rowData) => {
            return (
              <>
                {rowData.hasSubcontractor === true ? (
                  <i className={"pi pi-check text-success"}></i>
                ) : (
                  <i className={"pi pi-times text-danger"}></i>
                )}
              </>
            )
          }}></Column>
        }
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(ProjectListComponent);
