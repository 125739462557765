import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios";
import PartnerContactsComponent from "./PartnerContactsComponent";
import PartnerAddressesComponent from "./PartnerAddressesComponent";
import moment from "moment/moment";

const PartnerShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsPartner && window.App.state.propsPartner.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/partner/" + window.App.state.propsPartner.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.name}</h2>
      <div className={"p-grid"}>
        {formData.name &&
          <div className={"p-col"}>
            <small className={"p-text-uppercase"}>
              Típus
            </small>
            <p className={"p-mt-0 p-mb-0 p-text-bold"}>
              {formData.type === 'person' &&
                <>Magánszemély</>
              }
              {formData.type === 'organisation' &&
                <>Cég</>
              }
              {formData.type === 'other' &&
                <>Egyéb</>
              }
            </p>
          </div>
        }

      </div>
      {/*<hr/>*/}
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="">Cím</label>
              <span className="p-d-block p-text-bold">
                {(formData.addressPostalCode || formData.addressCity || formData.addressDetail) &&
                  <>
                    {formData.addressPostalCode} {formData.addressCity}, {formData.addressDetail}
                  </>
                }
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="">Telefon</label>
              <span className="p-d-block p-text-bold">
                  {formData.phone}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="">Email</label>
              <span className="p-d-block p-text-bold">
                  {formData.email}
              </span>
            </div>
          </div>
        </div>
        <hr className={"p-mt-2 p-mb-3"}/>
        {formData.type === 'organisation' &&
          <>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-4"}>
                <div className="p-field p-fluid">
                  <label htmlFor="type">Adószám</label>
                  <span className="p-d-block p-text-bold">
                {formData.vatNumber}
              </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <div className="p-field p-fluid">
                  <label htmlFor="type">Közösségi adószám</label>
                  <span className="p-d-block p-text-bold">
                {formData.communityVatNumber}
              </span>
                </div>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <div className="p-field p-fluid">
                  <label htmlFor="type">Egyéb jelzés</label>
                  <span className="p-d-block p-text-bold">
                {formData.other}
              </span>
                </div>
              </div>
            </div>
            <hr className={"p-mt-2 p-mb-3"}/>
          </>
        }
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="type">Aktív</label>
              <span className="p-d-block p-text-bold">
                {formData.active === true ? (
                  <i className={"pi pi-check text-success"}></i>
                ) : (
                  <i className={"pi pi-times text-danger"}></i>
                )}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="type">Vevő</label>
              <span className="p-d-block p-text-bold">
                {formData.customer === true ? (
                  <i className={"pi pi-check text-success"}></i>
                ) : (
                  <i className={"pi pi-times text-danger"}></i>
                )}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="type">Szállító</label>
              <span className="p-d-block p-text-bold">
                {formData.vendor === true ? (
                  <i className={"pi pi-check text-success"}></i>
                ) : (
                  <i className={"pi pi-times text-danger"}></i>
                )}
              </span>
            </div>
          </div>
        </div>
      </Panel>


      {
        formData && formData.id && localStorage.getItem('roles').toString().includes('role_partner_contact_read') &&
        <>
          <Panel headerTemplate={
            <div className={"p-grid show"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-list"}></i> Kapcsolattartók
                </h3>
              </div>
            </div>
          } className={"p-mt-3"}>
            <PartnerContactsComponent initialValues={formData} type={"show"}/>
          </Panel>
        </>
      }
      {
        formData && formData.id && localStorage.getItem('roles').toString().includes('role_partner_address_read') &&
        <>
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-list"}></i> Címek
                </h3>
              </div>
            </div>
          } className={"p-mt-3"}>
            <PartnerAddressesComponent initialValues={formData} type={"show"}/>
          </Panel>
        </>
      }
    </>
  )
}
export default withTranslation('common')(PartnerShowComponent);
