import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

import PartneraddressListService from "../../services/partneraddress/PartneraddressListService";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import PartneraddressFormService from "../../services/partneraddress/PartneraddressFormService";
import PartneraddressTypeService from "../../services/partneraddress/PartneraddressTypeService";
import CountryListService from "../../services/country/CountryListService";
import {InputSwitch} from "primereact/inputswitch";

const PartnerAddressesComponent = (props) => {

  const [addresses, setAddresses] = useState([]);
  const [types, setTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1,
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderPartnerAddresses: false})
      PartneraddressListService(JSON.stringify(lazyParams), props.initialValues.id)
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setAddresses(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_partner_address_write') &&
        <>
          <Button type="button"
                  icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    setAddressDialog(true)
                    setAddressFormData(rowData)
                    setAddressFormMethod('put')
                  }}/>
        </>
        }
      </React.Fragment>
    );
  }

  // dialog
  const addressValidate = (data) => {
    let errors = {};
    return errors;
  };

  const [addressDialog, setAddressDialog] = useState(false);
  const [addressFormData, setAddressFormData] = useState({}); // set on dialog open!
  const [addressFormMethod, setAddressFormMethod] = useState('post');

  const addressOnSubmit = (data, form) => {
    setLoading(true)
    PartneraddressFormService(data, addressFormMethod, props.initialValues.id, addressFormData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        PartneraddressListService(JSON.stringify({
          first: 0,
          rows: 9999,
          page: 0,
        }), props.initialValues.id)
          .then(response => {
            setAddresses(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setAddressDialog(false)
        setLoading(false)
        setAddressFormData({})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const type = (id) => {
    if (types && types[0]) {
      let type = types.filter(item => item.id === id)
      if (type && type[0] && type[0].name) {
        return type[0].name
      }
    }
  }

  const addressCountry = (code) => {
    if (countries && countries[0]) {
      let country = countries.filter(item => item.code === code)
      if (country && country[0] && country[0].name) {
        return country[0].name
      }
    }
  }

  useEffect(() => {
    PartneraddressTypeService(props.initialValues.id)
      .then(response => {
        setTypes(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    CountryListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setCountries(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPartnerAddresses === true])

  return (

    <>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={addresses} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column header="Típus" body={(rowData) => {
          return (
            <>{type(rowData.type)}</>
          )
        }}></Column>
        <Column header="Ország" body={(rowData) => {
          return (
            <>{addressCountry(rowData.addressCountryCode)}</>
          )
        }}></Column>
        <Column field="addressPostalCode" header="IRSZ"></Column>
        <Column field="addressCity" header="Város"></Column>
        <Column field="addressDetail" header="Cím"></Column>
        <Column field="active" header="Aktív" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <span id={rowData.id}>
            {rowData.active === true ? (
              <i className={"pi pi-check text-success"}></i>
            ) : (
              <i className={"pi pi-times text-danger"}></i>
            )}
            </span>
          )
        }}></Column>
        {localStorage.getItem('roles').toString().includes('role_partner_address_write') && props.type !== 'show' &&
          <Column body={actionBodyTemplate} className={"p-text-nowrap p-text-right"}></Column>
        }
      </DataTable>
      {localStorage.getItem('roles').toString().includes('role_partner_address_write') && props.type !== 'show' &&
      <>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="button" label="Új cím hozzáadása" icon="pi pi-plus" className="p-button-success  "
                    onClick={() => {
                      setAddressDialog(true)
                      setAddressFormMethod('post')
                    }}
            />
          </div>
        </div>
        <Dialog visible={addressDialog}
                style={{width: '750px'}}
                header="Cím hozzáadása"
                modal
                onHide={() => {
                  setAddressDialog(false)
                }}>
          <Form onSubmit={addressOnSubmit}
                initialValues={addressFormData}
                validate={addressValidate}
                render={({handleSubmit, submitting}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid"}>
                      <div className={"p-col"}>
                        <Field name="type" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="type"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                            <span className="p-d-block">
                                <Dropdown options={types}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"type"}
                                          emptyMessage={"Még nincs típus..."}
                                          onChange={(e) => {
                                            setAddressFormData({
                                              ...addressFormData,
                                              type: e.value
                                            })
                                          }}
                                          value={addressFormData.type}
                                />
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col"}>
                        <Field name="note" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="note"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                            <span className="p-d-block">
                              <InputSwitch
                                className={"p-mt-1"}
                                checked={addressFormData.active}
                                value={addressFormData.active}
                                onChange={e => setAddressFormData({...addressFormData, active: e.target.value})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col"}>
                        <Field name="addressCountryCode" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="addressCountryCode"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Ország</label>
                            <span className="p-d-block">
                              <Dropdown options={countries}
                                        optionLabel="name"
                                        optionValue="code"
                                        id={"addressCountryCode"}
                                        emptyMessage={"Még nincs ország..."}
                                        onChange={(e) => {
                                          setAddressFormData({
                                            ...addressFormData,
                                            addressCountryCode: e.value
                                          })
                                        }}
                                        value={addressFormData.addressCountryCode}
                              />
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid"}>
                      <div className={"p-col"}>
                        <Field name="addressPostalCode" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="addressPostalCode"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>IRSZ</label>
                            <span className="p-d-block">
                              <InputText id="addressPostalCode" {...input}
                                         onChange={(e) => {
                                           setAddressFormData({
                                             ...addressFormData,
                                             addressPostalCode: e.target.value
                                           })
                                         }}
                                         value={addressFormData.addressPostalCode}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col"}>
                        <Field name="addressCity" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="addressCity"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Város</label>
                            <span className="p-d-block">
                              <InputText id="addressCity" {...input}
                                         onChange={(e) => {
                                           setAddressFormData({
                                             ...addressFormData,
                                             addressCity: e.target.value
                                           })
                                         }}
                                         value={addressFormData.addressCity}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col"}>
                        <Field name="addressDetail" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="addressDetail"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Cím</label>
                            <span className="p-d-block">
                              <InputText id="addressDetail" {...input}
                                         onChange={(e) => {
                                           setAddressFormData({
                                             ...addressFormData,
                                             addressDetail: e.target.value
                                           })
                                         }}
                                         value={addressFormData.addressDetail}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12"}>
                        <Field name="note" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="note"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Megjegyzés</label>
                            <span className="p-d-block">
                              <InputText id="note" {...input}
                                         onChange={(e) => {
                                           setAddressFormData({
                                             ...addressFormData,
                                             note: e.target.value
                                           })
                                         }}
                                         value={addressFormData.note}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-1"}>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      </div>
                      <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                        <Button type="submit" label="Mentés" icon="pi pi-check"
                                loading={loading}/>
                      </div>
                    </div>
                  </form>
                )}/>
        </Dialog>
      </>
      }
    </>
  )
}
export default withTranslation('common')(PartnerAddressesComponent);
