import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import currencyFormatter from "currency-formatter";

import ProductListService from "../../services/product/ProductListService";
import ProductDeleteService from "../../services/product/ProductDeleteService";
import axios from "axios";

const ProductListComponent = (props) => {

  const {t} = props;
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProductList: false})
      ProductListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProduct(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteProduct = (rowData) => {
    ProductDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_product_write') &&
        <Button icon="pi pi-pencil" className="  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarProductUpdate: true,
                    props: rowData
                  })
                }}/>
        }
        {localStorage.getItem('roles').toString().includes('role_product_write') &&
          <>
            {rowData && rowData.deleted_at_snapshot ? (
              <Button icon="pi pi-undo" className="p-button-sm p-button-success"
                      tooltip="Visszaállítás"
                      tooltipOptions={{position: 'left'}}
                      onClick={() => {
                        axios({
                          method: 'get',
                          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "/api/v2/project/" + rowData.id + "/restore",
                          params: {},
                          headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                            'App-Network': localStorage.getItem("networkId")
                          }
                        })
                          .then(response => {
                            window.App.toastShow('success', t('success_restore'),);
                            loadLazyData();
                          })
                          .catch(error => {
                            window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                          })
                      }}
              />
            ) : (
              <Button icon="pi pi-trash" className=" p-button-danger"
                      tooltip="Törlés"
                      onClick={() => {
                        if (window.confirm('A törlés megerősítésére van szükség!')) {
                          deleteProduct(rowData)
                        }
                      }}
              />
            )}
          </>
        }
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderProductList === true])

  return (
    <>
      {props && props.view === 'simple' &&
        <>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-left"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-col-align-center p-text-center p-text-lg-right"}>
              {lazyParams && lazyParams.deleted === 1 ? (
                <Button type="button" icon={"pi pi-eye-slash"}
                        onClick={() => {
                          let _lazyParams = lazyParams
                          delete lazyParams.deleted
                          setLazyParams(_lazyParams)
                          loadLazyData()
                        }}
                        className="p-button-danger"/>
              ) : (
                <Button type="button" icon={"pi pi-eye"}
                        onClick={() => {
                          setLazyParams({
                            ...lazyParams,
                            deleted: 1
                          })
                        }}
                        className="p-button-success"/>
              )}
              {localStorage.getItem('roles').toString().includes('role_inventory_transfer_write') &&
                <a onClick={() => window.App.setState({
                  sidebarInventorytransferCreate: true,
                  props: {
                    action: 'move'
                  }
                })}
                   className={"p-button  p-button-secondary p-ml-md-2 p-my-1 p-my-lg-0"}>
                  <i className={"pi pi-reply"}></i>
                  &nbsp;
                  Árumozgatás
                </a>
              }
              {localStorage.getItem('roles').toString().includes('role_product_write') &&
                <a onClick={() => window.App.setState({sidebarProductImport: true})}
                   className={"p-button  p-button-secondary p-ml-md-2 p-my-1 p-my-lg-0"}>
                  <i className={"pi pi-cloud-upload"}></i>
                  &nbsp;
                  Terméktörzs importálása
                </a>
              }
              {localStorage.getItem('roles').toString().includes('role_product_write') &&
                <a onClick={() => window.App.setState({sidebarProductCreate: true})}
                   className={"p-button  p-button-success p-ml-md-2 p-my-1 p-my-lg-0"}>
                  <i className={"pi pi-plus"}></i>
                  &nbsp;
                  Új termék
                </a>
              }
            </div>
          </div>
        </>
      }
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={product} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        <Column field="name" header="Termék név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="unitName" header="Me. egység" sortable></Column>
        <Column field="manufacturerName" header="Gyártó" sortable filter filterPlaceholder="Gyártó"></Column>
        <Column field="upc" header="Gyártó cikkszám" sortable filter filterPlaceholder="Gyártó cikkszám"></Column>
        <Column field="sku" header="Termék cikkszám" sortable filter filterPlaceholder="Termék cikkszám"></Column>
        <Column field="averagePurchaseUnitPrice" header="Átlag nettó beszer. ért." sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.averagePurchaseUnitPrice, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-right p-text-nowrap"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(ProductListComponent);
